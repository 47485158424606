@tailwind base;
@tailwind components;
@tailwind utilities;

.appBackground{
  background-image: url(./img/mabre14.jpg);
  
}
  
.footerBackground{
  background-image: url(./img/carriere.webp);
}

.TopBackground{
  background-image: url(./img/carriere.webp);
  
}

.ProductBackground{
  background-image: url(./img/mabre14.jpg);

}
  .show{
    display: block;
  }
  
  .hidden{
    display: none;
  }
  
  .active{
    background-color: antiquewhite;
  }
  
  html, body{
    scroll-behavior: smooth;
    overflow-x: hidden;
  }
  
  



@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100;0,200;1,100;1,200;1,400&family=Bodoni+Moda:ital,opsz,wght@0,6..96,400;0,6..96,500;0,6..96,600;0,6..96,700;0,6..96,800;1,6..96,400;1,6..96,500;1,6..96,600;1,6..96,700;1,6..96,800;1,6..96,900&family=Montserrat+Alternates:ital,wght@1,800&family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;0,900;1,200;1,300;1,500&family=Outfit:wght@200;500&family=Roboto:ital,wght@0,100;0,300;0,700;1,400&display=swap');

  body {
font-family: 'Bodoni Moda', serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Montserrat Alternates', sans-serif;
font-family: 'Outfit', sans-serif;
font-family: 'Roboto', sans-serif;
  }